import Mock from '../mock';

const equipmentsDB = {
    equipments: [
        {
            'id': '5a7b73f98bed15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46d4',
            'volume' : '7m3',
            'dimension' : '550L x 225l x 85H'
        },
        {
            'id': '5a7b73f98bgf15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46d4',
            'volume' : '15m3',
            'dimension' : '610L x 240l x 177H'
        },
        {
            'id': '5a7b73f98bed15m84d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46d4',
            'volume' : '20m3',
            'dimension' : '580L x 250l x 183H'
        },
        {
            'id': '5a7b00f98bed15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46d4',
            'volume' : '30m3',
            'dimension' : '620L x 250l x 245H'
        },
        {
            'id': '5a7b73f98aze15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46d4',
            'volume' : '35m3',
            'dimension' : '630L x 250l x 280H'
        },
        {
            'id': '5a7b72d98aze15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46j8',
            'volume' : '6m3',
            'dimension' : '190L x 445l x 81H'
        },
        {
            'id': '5a7b72d98omp15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46j8',
            'volume' : '8m3',
            'dimension' : '190L x 445l x 111H'
        },
        {
            'id': '5a7b72d98y7u15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46j8',
            'volume' : '10m3',
            'dimension' : '190L x 445l x 153H'
        },
        {
            'id': '5a7b73f76bem99c94d1e46b0',
            'type_id': '5a7b73f76bed15c94d1e46b0',
            'volume' : '1m3',
            'dimension' : '88L x 88l x 105H'
        },
        {
            'id': '5a7b73f76beo99c94d1e46b0',
            'type_id': '5a7b73f76bed15c94d1e46a2',
            'volume' : '10m3',
            'dimension' : '545L x 231l x 262H'
        },
        {
            'id': '5a7b73f76beo99c94d1e4321',
            'type_id': '5a7b73f76bed15c94d1e46a2',
            'volume' : '15m3',
            'dimension' : '545L x 231l x 262H'
        },
        {
            'id': '5a7b73fgvbeo99c94d1e46b0',
            'type_id': '5a7b73f76bed15c94d1e46a2',
            'volume' : '20m3',
            'dimension' : '545L x 231l x 262H'
        },
        {
            'id': '509b73f76beo99c94d1e46b0',
            'type_id': '5a7b73f76bed15c94d1e46a2',
            'volume' : '24m3',
            'dimension' : '545L x 231l x 262H'
        },
        {
            'id': '50jk73f76beo99c94d1e46b0',
            'type_id': '5a7b73f76bed15c94d1e46d1',
            'volume' : '65L',
            'dimension' : '60L x 40l x 31H'
        },
        {
            'id': '50jk73f76beo99k94d1e46b0',
            'type_id': '5a7b73f76bed15c94d1e46d1',
            'volume' : '75L',
            'dimension' : '60L x 40l x 41H'
        },
        {
            'id': '50jk73f76beo99c9321e46b0',
            'type_id': '5a7b73f76bed15c94d1e46d1',
            'volume' : '373L',
            'dimension' : '120L x 100l x 58H'
        },
        {
            'id': '50jk73f76beo99c94d31d6b0',
            'type_id': '5a7b73f76bed15c94d1e46d1',
            'volume' : '650L',
            'dimension' : '120L x 100l x 79H'
        },
        {
            'id': '5a7b73f76bed15c94d1e46m4',
            'type_id': '5a7b73f76bed15c94d1e46m4',
            'volume' : '5m3',
            'dimension' : '243L x 171l'
        },
        {
            'id': '20thl3f76beo99c94d31d6b0',
            'type_id': '5a7b73f76bed15c94d1e46d3',
            'volume' : '70L',
            'dimension' : '55L x 41l x 41H'
        },
        {
            'id': '50thd9d76beo99c94d31d6b0',
            'type_id': '5a7b73f76bed15c94d1e46d3',
            'volume' : '240L',
            'dimension' : '105L x 70l x641H'
        },
        {
            'id': '50thl3f76beojkl94d31d6b0',
            'type_id': '5a7b73f76bed15c94d1e46d3',
            'volume' : '415L',
            'dimension' : '109L x 85l x 72H'
        },
        {
            'id': '50thl3f768uyjkl94d31d6b0',
            'type_id': '5a7b73f76bed15c94d1e46t2',
        },
    ]
}



Mock.onGet('/api/equipments/all').reply(() => {
    return [200, equipmentsDB.equipments]
});

Mock.onGet('/api/equipments').reply((config) => {
    const id = config.data;
    const response = equipmentsDB.equipments.find((user) => user.id === id);
    return [200, response];
});

Mock.onGet('/api/type/equipments').reply((config) => {
    const id = config.data;
    const response = []
    // eslint-disable-next-line array-callback-return
    equipmentsDB.equipments.map((item) => {
        if (item.type_id === id) {
            response.push(item)
        }
    });
    return [200, response];
});

Mock.onPost('/api/equipments/delete').reply(config => {
    let user = JSON.parse(config.data);
    let index = { i: 0 };
    equipmentsDB.equipments.forEach(element => {
        if (element.id === user.id) {
            return [200, equipmentsDB.equipments.splice(index.i, 1)]
        }
        index.i++
    });
    return [200, equipmentsDB.equipments]
});


Mock.onPost('/api/equipments/update').reply((config) => {
    let user = JSON.parse(config.data);
    let index = { i: 0 };
    equipmentsDB.equipments.forEach(element => {
        if (element.id === user.id) {
            equipmentsDB.equipments[index.i] = user
            return [200, equipmentsDB.equipments]
        }
        index.i++
    });
    return [200, equipmentsDB.equipments]
});

Mock.onPost('/api/equipments/add').reply((config) => {
    let user = JSON.parse(config.data);
    equipmentsDB.equipments.push(user)
    return [200, equipmentsDB.equipments]
});