import { lazy } from "react";

const CardWidget = lazy(() => import("./CardWidget"));

const ListWidget = lazy(() => import("./ListWidget"));

const widgetsRoute = [
  {
    path: "/widgets/card",
    component: CardWidget
  },
  {
    path: "/widgets/list",
    component: ListWidget
  },
];

export default widgetsRoute;
