import {
  SET_NEED_EQUIPMENT,
  SET_EQUIPMENTS_LIST,
  SET_SERVICE_PERIOD,
  SET_SERVICE_TYPE,
  SET_ZIPCODE,
  SET_EQUIPMENTS_VOLUMES,
  SET_WASTES_TYPES,
  SET_VALIDATE,
  SET_DATES,
  SET_WASTES_LIST,
  SET_WASTES_VOLUME,
  SET_WASTES_CONDITIONING,
  SET_WASTES_PICTURE,
  SET_ADDRESS
} from "../actions/QuotationActions";

const initialState = {
  validate: false,
  zipcode : '',
  service : {
    type : '', // spot - recurrent
    duration : {
      length : 0,
      period : '' // weekly - monthly
    }
  },
  equipment : {
    need : '',
    equipmentList : {},
    equipmentVolume : {},
  },
  wastes : {
    wastesType : {},
    wastesList : {},
    wastesVolume : '',
    wastesConditioning: {},
    wastesPicture : '',
  },
  date : {
    dropOff : {
      date : '',
      hour : ''
    },
    pickup : {
      date : '',
      hour : ''
    },
    recurrent : {
      day : ''
    }
  },
  address: {
    id : ''
  }
};

const QuotationReducer = function(state = initialState, action) {
  switch (action.type) {
    case SET_ZIPCODE: {
      return {
        ...state,
        zipcode: action.payload
      }
    }
    case SET_SERVICE_TYPE:
    case SET_SERVICE_PERIOD: {
      return {
        ...state,
        service: {...action.payload}
      }
    }
    case SET_NEED_EQUIPMENT:
    case SET_EQUIPMENTS_LIST:
    case SET_EQUIPMENTS_VOLUMES:{
      return {
        ...state,
        equipment: {...action.payload}
      }
    }

    case SET_WASTES_TYPES:
    case SET_WASTES_LIST:
    case SET_WASTES_VOLUME:
    case SET_WASTES_CONDITIONING:
    case SET_WASTES_PICTURE: {
      return {
        ...state,
        wastes: {...action.payload}
      }
    }

    case SET_DATES : {
      return {
          ...state,
          date: action.payload
      }
    }
    case SET_ADDRESS : {
      return {
        ...state,
        address: action.payload
      }
    }
    case SET_VALIDATE: {
      return {
        ...state,
        validate: action.payload
      }
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default QuotationReducer;
