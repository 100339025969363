import {SET_COMPANY, SET_EQUIPMENTS_COMPANY, SET_VOLUME_COMPANY, SET_SECTORS_COMPANY} from "../actions/CompanyActions";

const initialState = {
    id: '',
    name: '',
    siret: '',
    cp: {
        zip: '',
        commune: '',
        country: '',
    },
    phone: '',
    contacts: [],
    destinations: [],
    equipmentType: [],
    equipments: [],
    wasteType: [],
    wastes: [],
    sectors : []
};

const CompanyReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_COMPANY:
        case SET_EQUIPMENTS_COMPANY:
        case SET_SECTORS_COMPANY:
        case SET_VOLUME_COMPANY:{
            return {
                ...state,
                ...action.payload
            }
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default CompanyReducer;
