export const SET_ZIPCODE = "SET_ZIPCODE";
export const SET_SERVICE_TYPE = "SET_SERVICE_TYPE";
export const SET_SERVICE_PERIOD = "SET_SERVICE_PERIOD";
export const SET_NEED_EQUIPMENT = "SET_NEED_EQUIPMENT";
export const SET_EQUIPMENTS_LIST = "SET_EQUIPMENTS_LIST";
export const SET_EQUIPMENTS_VOLUMES = "SET_EQUIPMENTS_VOLUMES";
export const SET_WASTES_TYPES = "SET_WASTES_TYPES";
export const SET_WASTES_VOLUME = "SET_WASTES_VOLUME";
export const SET_WASTES_CONDITIONING = "SET_WASTES_CONDITIONING";
export const SET_WASTES_PICTURE = "SET_WASTES_PICTURE";
export const SET_WASTES_LIST = "SET_WASTES_LIST";
export const SET_VALIDATE = "SET_VALIDATE";
export const SET_DATES = "SET_DATES";
export const SET_ADDRESS = "SET_ADDRESS";

export const setZipCode = (zipcode) => dispatch => {
  dispatch({
    type: SET_ZIPCODE,
    payload: zipcode
  });
};

export const setServiceType = (service) => dispatch => {
  dispatch({
    type: SET_SERVICE_TYPE,
    payload: service
  });
};

export const setServicePeriod = (service) => dispatch => {
  dispatch({
    type: SET_SERVICE_PERIOD,
    payload: service
  });
};

export const setNeedEquipment = (need) => dispatch => {
  dispatch({
    type: SET_NEED_EQUIPMENT,
    payload: {...need}
  });
};

export const setEquipmentsList = (equipmentsList) => dispatch => {
  dispatch({
    type: SET_EQUIPMENTS_LIST,
    payload: {...equipmentsList}
  });
};

export const setEquipmentsVolumes = (equipmentsVolumes) => dispatch => {
  dispatch({
    type: SET_EQUIPMENTS_VOLUMES,
    payload: {...equipmentsVolumes}
  });
};

export const setWastesTypes = (wastesTypes) => dispatch => {
  dispatch({
    type: SET_WASTES_TYPES,
    payload: {...wastesTypes}
  });
};

export const setWastesList = (wastesList) => dispatch => {
  dispatch({
    type: SET_WASTES_LIST,
    payload: {...wastesList}
  });
};

export const setWastesVolume = (wastesVolume) => dispatch => {
  dispatch({
    type: SET_WASTES_VOLUME,
    payload: {...wastesVolume}
  });
};

export const setWastesConditioning = (wastesVolume) => dispatch => {
  dispatch({
    type: SET_WASTES_CONDITIONING,
    payload: {...wastesVolume}
  });
};

export const setWastesPicture = (wastesVolume) => dispatch => {
  dispatch({
    type: SET_WASTES_PICTURE,
    payload: {...wastesVolume}
  });
};



export const setDates = (dates) => dispatch => {
  dispatch({
    type: SET_DATES,
    payload: {...dates}
  });
};

export const setAddress = (addressId) => dispatch => {
  dispatch({
    type: SET_ADDRESS,
    payload: {...addressId}
  });
};


export const validateQuotation = (validate) => dispatch => {
  dispatch({
    type: SET_VALIDATE,
    payload: {...validate}
  });
};



