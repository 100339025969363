import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Account = lazy(() => import("./Account"));

const accountRoutes = [
  {
    path: "/account",
    component: Account,
    auth: authRoles.user
  },
];

export default accountRoutes;
