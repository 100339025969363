import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import NotificationReducer from "./NotificationReducer";
import QuotationReducer from "./QuotationReducer";
import CompanyReducer from "./CompanyReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  notification: NotificationReducer,
  quotation: QuotationReducer,
  company: CompanyReducer
});

export default RootReducer;
