import Mock from '../mock';

const equipmentsTypeDB = {
    types: [
        {
            'id': '5a7b73f76bed15c94d1e46d4',
            'name': 'Dump (Tipping trailer)',
            'logo': '/assets/images/wasteforce/equipments/tipping_trailer.svg',
        },
        {
            'id': '5a7b73f76bed15c94d1e46j8',
            'name': 'Dump (Skiploader)',
            'logo': '/assets/images/wasteforce/equipments/skiploader_dump.svg',
        },
        {
            'id': '5a7b73f76bed15c94d1e46b0',
            'name': 'Big Bag',
            'logo': '/assets/images/wasteforce/equipments/big_bag.svg',
        },
        {
            'id': '5a7b73f76bed15c94d1e46a2',
            'name': 'Compactor',
            'logo': '/assets/images/wasteforce/equipments/compactor_waste.svg',
        },
        {
            'id': '5a7b73f76bed15c94d1e46d1',
            'name': 'Box Palet',
            'logo': '/assets/images/wasteforce/equipments/box_palet.svg',
        },
        {
            'id': '5a7b73f76bed15c94d1e46m4',
            'name': 'Borne',
            'logo': '/assets/images/wasteforce/equipments/borne_waste.svg',
        },
        {
            'id': '5a7b73f76bed15c94d1e46d3',
            'name': 'Confidential Collector',
            'logo': '/assets/images/wasteforce/equipments/confidential_collector.svg',
        },
        {
            'id': '5a7b73f76bed15c94d1e46t2',
            'name': 'Mesh box Palets',
            'logo': '/assets/images/wasteforce/equipments/mesh_box_palets.svg',
        },
      ]
}



Mock.onGet('/api/equipments/types/all').reply(config => {
    return [200, equipmentsTypeDB.types]
});

Mock.onGet('/api/equipments/types').reply((config) => {
    const id = config.data;
    const response = equipmentsTypeDB.types.find((user) => user.id === id);
    return [200, response];
});

Mock.onPost('/api/equipments/types/delete').reply(config => {
    let user = JSON.parse(config.data);
    let index = { i: 0 };
    equipmentsTypeDB.types.forEach(element => {
        if (element.id === user.id) {
            return [200, equipmentsTypeDB.types.splice(index.i, 1)]
        }
        index.i++
    });
    return [200, equipmentsTypeDB.types]
});


Mock.onPost('/api/equipments/types/update').reply((config) => {
    let user = JSON.parse(config.data);
    let index = { i: 0 };
    equipmentsTypeDB.types.forEach(element => {
        if (element.id === user.id) {
            equipmentsTypeDB.types[index.i] = user
            return [200, equipmentsTypeDB.types]
        }
        index.i++
    });
    return [200, equipmentsTypeDB.types]
});

Mock.onPost('/api/equipments/types/add').reply((config) => {
    let user = JSON.parse(config.data);
    equipmentsTypeDB.types.push(user)
    return [200, equipmentsTypeDB.types]
});