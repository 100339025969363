import axios from "axios";
import localStorageService from "./localStorageService";
import jwt_decode from "jwt-decode";
import {
    LOGIN_CHECK,
    NEW_USER
} from "../../utils/utils";
class JwtAuthService {

    user = {
        userId: "",
        role: '',
        displayName: "",
        email: "",
        photoURL: "",
        age: 0,
        token: ""
    }

    loginWithEmailAndPassword = (email, password) => {
        return axios.post(LOGIN_CHECK, {'username': email, 'password': password})
            .then(res => {
                const data = res.data;
                this.setSession(data.token);
                let decoded = jwt_decode(data.token);
                this.user.token = data.token;
                this.user.email = decoded.username;
                this.user.role = decoded.roles;
                this.setUser(this.user);
                return this.user;
            })
    };

    registerWithEmailAndPassword = (role, email, password) => {
        return axios.post(NEW_USER,
            {
                'type': role,
                'email': email,
                'password': password
            })
            .then(res => {
            })
    };

    loginWithToken = () => {
        const token = localStorage.getItem('jwt_token')
        if (token) {
            this.setSession(token);
            let decoded = jwt_decode(token);
            this.user.email = decoded.username;
            this.user.role = decoded.roles;
            this.setUser(this.user);
            return new Promise((resolve, reject) => {
            }).then(data => {
                this.setSession(token);
                this.setUser(this.user);
                return data;
            });
        }
    };


    logout = () => {
        this.setSession(null);
        this.removeUser();
    }

    setSession = token => {
        if (token) {
            localStorage.setItem("jwt_token", token);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        }
    };
    setUser = (user) => {
        localStorageService.setItem("auth_user", user);
    }
    removeUser = () => {
        localStorage.removeItem("auth_user");
    }
}

export default new JwtAuthService();
