export const BASE_URL = "https://wasteforce.kaytl.in";
export const FRONT_URL = "https://wasteforce.cameleon-consulting.org";

export const API_GOUV = "https://geo.api.gouv.fr";

export const LOGIN_CHECK = BASE_URL + '/api/login_check';
export const NEW_USER = BASE_URL + '/user/new';

//Wastes
export const GET_WASTE = BASE_URL + '/wastes/';
export const GET_WASTE_TYPE_ID = BASE_URL + '/waste/type/';

//Waste Type
export const GET_WASTES_TYPE = BASE_URL + '/waste_types/';

//Equipment
export const GET_EQUIPMENT = BASE_URL + '/equipment/';
export const GET_EQUIPMENT_BY_TYPE = BASE_URL + '/equipment/type/';

//Equipment Type
export const GET_EQUIPMENT_TYPE = BASE_URL + '/equipment_types/';

//Quotation
export const GET_QUOTATIONS = BASE_URL + '/quotation';
export const GET_QUOTATION = BASE_URL + '/quotation/{id}';
export const DELETE_QUOTATION = BASE_URL + '/quotations/{id}';
export const GET_USER_QUOTATIONS = BASE_URL + '/user/quotation'
export const NEW_QUOTATION = BASE_URL + '/quotation/new'

//Contact
export const GET_USER_CONTACT = BASE_URL + '/user/contacts';
export const NEW_CONTACT = BASE_URL + '/contact/new'
export const EDIT_CONTACT = BASE_URL + '/contact/{id}/edit'

//Address
export const GET_USER_ADDRESS = BASE_URL + '/user/address';
export const NEW_ADDRESS = BASE_URL + '/address/new'
export const EDIT_ADDRESS = BASE_URL + '/address/{id}/edit'

//Company
export const GET_COMPANY_USER = BASE_URL + '/user/company';
export const NEW_COMPANY = BASE_URL + '/company/new'
export const EDIT_COMPANY = BASE_URL + '/company/{id}/edit'
export const ADD_EQUIPMENT_COMPANY = BASE_URL + '/company/{id}/equipments'
export const GET_MATCH_COMPANY = BASE_URL + '/company/{id}/match'
export const ADD_WASTES_COMPANY = BASE_URL + '/company/{id}/wastes'

//Sectors
export const ADD_COMPANY_SECTOR = BASE_URL + '/sectors/company/{id}'

//Offers

export const NEW_OFFER = BASE_URL + '/offers/new'
export const EDIT_OFFER = BASE_URL + '/offers/{id}/edit'
export const ACCEPT_OFFER = BASE_URL + '/offers/{id}/accept'
export const DELETE_OFFER = BASE_URL + '/offers/{id}/delete'
export const DECLINE_OFFER = BASE_URL + '/offers/{id}/decline'
export const GET_OFFER_QUOTATION = BASE_URL + '/quotation/{id}/offers'