import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Offers = lazy(() => import("./Offers"));

const offersRoutes = [
  {
    path: "/offers",
    component: Offers,
    auth: authRoles.user
  },
];

export default offersRoutes;
