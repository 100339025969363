import Mock from '../mock';

const wastesDB = {
    wastes: [
        {
            'id': '5a7b73f98bed15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46d4',
            'name' : 'Paper',
        },
        {
            'id': '5a7b73f98bgf15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46d4',
            'name' : 'Cardboard'
        },
        {
            'id': '5a7b73f98bed15m84d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46d4',
            'name' : 'Plastics'
        },


        {
            'id': '5a7b00f98bed15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46j8',
            'name' : 'OIW (Ordinary Industrial Waste)'
        },
        {
            'id': '5a7b73f98aze15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46j8',
            'name' : 'Mixed rubble'
        },
        {
            'id': '5a7b72d98aze15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46j8',
            'name' : 'Clean rubble'
        },


        {
            'id': '5a7b72d98omp15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46b0',
            'name' : 'Computer Equipment & Printer'
        },
        {
            'id': '5a7b72d98y7u15c94d1e46d4',
            'type_id': '5a7b73f76bed15c94d1e46b0',
            'name' : 'Lamps'
        },
        {
            'id': '5a7b73f76bem99c94d1e46b0',
            'type_id': '5a7b73f76bed15c94d1e46b0',
            'name' : 'Appliances'
        },


        {
            'id': '5a7b73f76beo99c94d1e46b0',
            'type_id': '5a7b73f76bed15c94d1e46a2',
            'name' : 'Paper'
        },
        {
            'id': '5a7b73f76beo99c94d1e4321',
            'type_id': '5a7b73f76bed15c94d1e46a2',
            'name' : 'Cardboard'
        },
        {
            'id': '5a7b73fgvbeo99c94d1e46b0',
            'type_id': '5a7b73f76bed15c94d1e46a2',
            'name' : 'Computer Equipment & Printer'
        },

        {
            'id': '50jk73f76beo99c94d1e46b0',
            'type_id': '5a7b73f76bed15c94d1e46d1',
            'name' : 'Solvents'
        },
        {
            'id': '50jk73f76beo99k94d1e46b0',
            'type_id': '5a7b73f76bed15c94d1e46d1',
            'name' : 'Hydrocarbons'
        },
        {
            'id': '50jk73f76beo99c9321e46b0',
            'type_id': '5a7b73f76bed15c94d1e46d1',
            'name' : 'Phytosanitary waste'
        },
    ]
}



Mock.onGet('/api/wastes/all').reply(() => {
    return [200, wastesDB.wastes]
});

Mock.onGet('/api/wastes').reply((config) => {
    const id = config.data;
    const response = wastesDB.wastes.find((user) => user.id === id);
    return [200, response];
});

Mock.onGet('/api/type/wastes').reply((config) => {
    const id = config.data;
    const response = []
    // eslint-disable-next-line array-callback-return
    wastesDB.wastes.map((item, index) => {
        if (item.type_id === id) {
            response.push(item)
        }
    });
    return [200, response];
});

Mock.onPost('/api/wastes/delete').reply(config => {
    let user = JSON.parse(config.data);
    let index = { i: 0 };
    wastesDB.wastes.forEach(element => {
        if (element.id === user.id) {
            return [200, wastesDB.wastes.splice(index.i, 1)]
        }
        index.i++
    });
    return [200, wastesDB.wastes]
});


Mock.onPost('/api/wastes/update').reply((config) => {
    let user = JSON.parse(config.data);
    let index = { i: 0 };
    wastesDB.wastes.forEach(element => {
        if (element.id === user.id) {
            wastesDB.wastes[index.i] = user
            return [200, wastesDB.wastes]
        }
        index.i++
    });
    return [200, wastesDB.wastes]
});

Mock.onPost('/api/wastes/add').reply((config) => {
    let user = JSON.parse(config.data);
    wastesDB.wastes.push(user)
    return [200, wastesDB.wastes]
});