import { lazy } from "react";


const UserProfile = lazy(() => import("./UserProfile"));

const pagesRoutes = [
  {
    path: "/pages/user-profile",
    component: UserProfile
  },
];

export default pagesRoutes;
