import Mock from '../mock';

const wastesTypeDB = {
    types: [
        {
            'id': '5a7b73f76bed15c94d1e46d4',
            'name': 'Non Dangerous Waste',
            'logo': '/assets/images/wasteforce/wastes/non_dangerous_waste.svg',
        },
        {
            'id': '5a7b73f76bed15c94d1e46j8',
            'name': 'Construction Waste',
            'logo': '/assets/images/wasteforce/wastes/construction_waste.svg'
        },
        {
            'id': '5a7b73f76bed15c94d1e46b0',
            'name': 'Electronic Waste',
            'logo': '/assets/images/wasteforce/wastes/electronic_waste.svg'
        },
        {
            'id': '5a7b73f76bed15c94d1e46a2',
            'name': 'Office Waste',
            'logo': '/assets/images/wasteforce/wastes/office_waste.svg'
        },
        {
            'id': '5a7b73f76bed15c94d1e46d1',
            'name': 'Dangerous waste',
            'logo': '/assets/images/wasteforce/wastes/dangerous_wastes.svg'
        }
      ]
}



Mock.onGet('/api/wastes/types/all').reply(config => {
    return [200, wastesTypeDB.types]
});

Mock.onGet('/api/wastes/types').reply((config) => {
    const id = config.data;
    const response = wastesTypeDB.types.find((user) => user.id === id);
    return [200, response];
});

Mock.onPost('/api/wastes/types/delete').reply(config => {
    let user = JSON.parse(config.data);
    let index = { i: 0 };
    wastesTypeDB.types.forEach(element => {
        if (element.id === user.id) {
            return [200, wastesTypeDB.types.splice(index.i, 1)]
        }
        index.i++
    });
    return [200, wastesTypeDB.types]
});


Mock.onPost('/api/wastes/types/update').reply((config) => {
    let user = JSON.parse(config.data);
    let index = { i: 0 };
    wastesTypeDB.types.forEach(element => {
        if (element.id === user.id) {
            wastesTypeDB.types[index.i] = user
            return [200, wastesTypeDB.types]
        }
        index.i++
    });
    return [200, wastesTypeDB.types]
});

Mock.onPost('/api/wastes/types/add').reply((config) => {
    let user = JSON.parse(config.data);
    wastesTypeDB.types.push(user)
    return [200, wastesTypeDB.types]
});