export const SET_COMPANY = "SET_COMPANY";
export const SET_EQUIPMENTS_COMPANY = "SET_EQUIPMENTS_COMPANY";
export const SET_VOLUME_COMPANY = "SET_VOLUME_COMPANY";
export const SET_WASTES_COMPANY = "SET_WASTES_COMPANY";
export const SET_SECTORS_COMPANY = "SET_SECTORS_COMPANY";
export const SET_MATCH = "SET_MATCH";

export const setCompany = (payload) => dispatch => {
  dispatch({
    type: SET_COMPANY,
    payload: {...payload}
  });
};

export const setMatch = (payload) => dispatch => {
  dispatch({
    type: SET_MATCH,
    payload: {...payload}
  });
};

export const setEquipmentsCompany = (payload) => dispatch => {
  dispatch({
    type: SET_EQUIPMENTS_COMPANY,
    payload: {...payload}
  });
};

export const setVolumeCompany = (payload) => dispatch => {
  dispatch({
    type: SET_VOLUME_COMPANY,
    payload: {...payload}
  });
};

export const setWastesCompany = (payload) => dispatch => {
  dispatch({
    type: SET_WASTES_COMPANY,
    payload: {...payload}
  });
};

export const setSectorsCompany = (payload) => dispatch => {
  dispatch({
    type: SET_SECTORS_COMPANY,
    payload: {...payload}
  });
};

