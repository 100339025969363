import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Settingsprofile = lazy(() => import("./Settings-profile"));

const settingsProfileRoutes = [
  {
    path: "/settings-profile",
    component: Settingsprofile,
    auth: authRoles.user
  },
];

export default settingsProfileRoutes;
