import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Settings = lazy(() => import("./Settings"));

const settingsRoutes = [
  {
    path: "/settings",
    //component: Settings,
    auth: authRoles.user
  },
];

export default settingsRoutes;
